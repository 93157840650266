import { render } from "./homeCrazySports.vue?vue&type=template&id=73b2eb1f&scoped=true"
import script from "./homeCrazySports.vue?vue&type=script&lang=js"
export * from "./homeCrazySports.vue?vue&type=script&lang=js"

import "./homeCrazySports.vue?vue&type=style&index=0&id=73b2eb1f&rel=stylesheet%2Fscss&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-73b2eb1f"
/* hot reload */
if (module.hot) {
  script.__hmrId = "73b2eb1f"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('73b2eb1f', script)) {
    api.reload('73b2eb1f', script)
  }
  
  module.hot.accept("./homeCrazySports.vue?vue&type=template&id=73b2eb1f&scoped=true", () => {
    api.rerender('73b2eb1f', render)
  })

}

script.__file = "src/views/home/homeIndex/homeCrazySports.vue"

export default script